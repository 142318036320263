<template>
  <div class="patentInfo" ref="tableBoxRef1">
    <el-scrollbar>
      <div class="cont">
        <div class="cont-main col999">
          <div class="updateBox flex-between">
            <div class="type">临时医嘱</div>
            <div class="upButtom">
              <!-- <el-button
                class="color-green"
                type="primary"
                @click="openWeight('batchLongIerm')"
              >
                <i class="fa fa-plus-circle"></i>批量新增
              </el-button> -->
              <el-button class="color-main" type="primary" @click="openWeight('addLongIerm')">
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </div>
          </div>
          <div class="tables" >
            <el-table size="small" v-loading="tableLoading" row-key="id" :data="tableData" border
              style="width: 100%;" v-if="istable1">
              <el-table-column width="40" fixed prop="group_text" label="组" />
              <el-table-column width="60" label="分组号">
                <template #default="scope">
                  <el-input v-model="scope.row.sort" size="small" placeholder="请填写分组"
                    @change="(e) => editAdvice(e, scope.row, 4)" />
                </template>
              </el-table-column>
              <el-table-column width="100" fixed label="是否打印">
                <template #default="scope">
                  <el-checkbox v-model="scope.row.is_report1" label="" @change="
                      (val) => {
                        reportAdvice(val, scope.row)
                      }
                    "></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column width="170" fixed prop="drug" label="用药" />
              <el-table-column width="90" prop="use_type" label="用药方法" />
              <el-table-column width="100" prop="standard" label="规格" />
              <el-table-column width="90" label="计量">
                <template #default="scope">
                  <el-input v-model="scope.row.dosage" size="small" placeholder="请填写计量"
                    @change="(e) => editAdvice(e, scope.row, 1)" />
                </template>
              </el-table-column>
              <el-table-column width="80" size="small" prop="dosage_unit" label="计量单位" />
              <el-table-column width="60" prop="frequency" label="频度" />
              <el-table-column width="90" label="用量">
                <template #default="scope">
                  <el-input v-model="scope.row.num" size="small" placeholder="请填写计量" @change="(e) => editAdvice(e, scope.row, 2)" />
                </template>
              </el-table-column>
              <el-table-column width="80" prop="doctor_id_name" label="医生名" />
              <el-table-column width="110" show-overflow-tooltip prop="remark" label="备注" />
              <!-- <el-table-column width="70" prop="frequency" label="频度" /> -->
              <el-table-column width="200" label="开嘱时间">
                <template #default="scope">
                  <el-date-picker v-model="scope.row.start_time" size="small" type="datetime" value-format="YYYY-MM-DD HH:mm"
                    format="YYYY-MM-DD HH:mm" placeholder="开嘱时间" @change="(e) => editAdvice(e, scope.row, 3)">
                  </el-date-picker>
                </template>
              </el-table-column>
              <!-- <el-table-column width="150" prop="start_time" label="开嘱时间" /> -->
              <!-- <el-table-column width="160" prop="end_time" label="结束时间" /> -->
              <el-table-column width="80" prop="do_user_id_name" label="执行护士" />
              <el-table-column width="140" label="执行时间">
                <template #default="scope">
                  <el-time-picker size="small" v-model="scope.row.do_time" value-format="HH:mm:ss" format="HH:mm:ss"
                    placeholder="执行时间">
                  </el-time-picker>
                </template>
              </el-table-column>
              <el-table-column width="60" label="执行">
                <template #default="scope">
                  <el-checkbox v-model="scope.row.is_do" label="" @change="
                      (val) => {
                        adviceDoChe(val, scope.row)
                      }
                    "></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column width="80" prop="check_user_id_name" label="核对人" />
              <!-- <el-table-column width="150" label="核对时间">
                <template #default="scope">
                  <el-time-picker
                    v-model="scope.row.check_time"
                    value-format="HH:mm:ss"
                    format="HH:mm:ss"
                    placeholder="执行时间"
                  >
                  </el-time-picker>
                </template>
              </el-table-column> -->
              <el-table-column width="60" label="核对">
                <template #default="scope">
                  <el-checkbox v-model="scope.row.is_check" label="" @change="
                      (val) => {
                        adviceCheckChe(val, scope.row)
                      }
                    "></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="90">
                <template #default="scope">
                  <el-button type="text" size="small" @click="modParient(scope.row)">
                    <i class="txIons modify"></i>
                  </el-button>
                  <el-button type="text" size="small" @click="delParient(scope.row)">
                    <i class="txIons del"></i>
                  </el-button>
                  <!-- <el-button
                    type="text"
                    size="small"
                    @click="historyParient(scope.row)"
                  >
                    <i class="txIons history"></i>
                  </el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-scrollbar>

    <BaseDialog title="修改记录" :isshow="isHistory" @handleShow="
        (val) => {
          isHistory = val
        }
      " width="84%" height="auto">
      <el-table class="sss" size="small" :data="historyTable" border style="margin-top: 20px">
        <el-table-column fixed width="160px" prop="updated_time" label="修改时间" />
        <el-table-column fixed prop="doctor.name" label="修改人" />
        <el-table-column fixed prop="type_content" label="类别" />
        <el-table-column width="100px" prop="id" label="数据ID" />
        <el-table-column width="300px" prop="advice_content" label="医嘱内容" />
        <el-table-column width="150px" prop="dict_advice_type" label="医嘱类型编码" />
        <el-table-column width="150px" prop="dict_advice_type_name" label="医嘱类型名称" />
        <el-table-column width="150px" prop="dict_drug" label="药品编码" />
        <el-table-column width="120px" prop="drug" label="药品名称" />
        <el-table-column width="100px" prop="standard" label="规格" />
        <el-table-column width="90px" prop="dosage" label="单次用量" />
        <el-table-column width="140px" prop="dict_dosage_unit" label="单次用量单位" />
        <el-table-column width="150px" prop="dict_use_type" label="用药途径编码" />
        <el-table-column width="150px" label="用药途径名称" />
        <el-table-column width="120px" prop="dict_frequency" label="执行频次" />
        <el-table-column width="90px" prop="num" label="数量" />
        <el-table-column width="90px" prop="num_unit_name" label="单位" />
        <el-table-column width="160px" label="是否按收费组套收费" />

        <el-table-column width="120px" label="收费组套ID" />
        <!-- <el-table-column
          width="150px"
          prop="charge_group_name"
          label="收费组套名称"
        /> -->
        <el-table-column width="150px" label="收费组套名称" />
        <el-table-column width="210px" label="记录当前组套下选择的项目ID" />
        <el-table-column width="120px" prop="self_provided" label="个人库存" />
        <el-table-column width="120px" prop="start_time" label="开嘱时间" />
        <el-table-column width="120px" prop="do_time" label="执行时间" />
        <el-table-column width="120px" prop="check_time" label="核对时间" />
        <el-table-column width="120px" prop="create_doctor_id" label="开嘱医生ID" />
        <el-table-column width="140px" prop="create_doctor_id_name" label="开嘱医生姓名" />
        <el-table-column width="120px" prop="do_user_id" label="执行护士Id" />
        <el-table-column width="120px" prop="do_user_id_name" label="执行护士姓名" />
        <el-table-column width="120px" prop="check_user_id" label="核对护士ID" />
        <el-table-column width="120px" prop="check_user_id_name" label="核对护士姓名" />
        <el-table-column width="120px" prop="dict_drug_type" label="药品类型编码" />
        <el-table-column width="140px" prop="dict_drug_type_name" label="药品类型名称" />
        <!-- <el-table-column width="120px" prop="advice_content" label="执行标识" />
        <el-table-column width="120px" prop="advice_content" label="核对标识" />
        <el-table-column width="120px" prop="advice_content" label="更新标识" /> -->
        <!-- <el-table-column
          width="150px"
          prop="advice_content"
          label="是否外部导入数据"
        /> -->
        <el-table-column width="140px" prop="source_name" label="数据来源类型" />
        <el-table-column width="150px" label="开嘱医生签名图片" />
        <el-table-column width="150px" label="执行护士签名图片" />
        <el-table-column width="150px" label="核对护士签名图片" />
        <!-- <el-table-column
          width="180px"
          prop="advice_content"
          label="标识属于同一组套的颜色"
        /> -->
        <el-table-column width="120px" prop="advice_group_id" label="医嘱组套ID" />
        <el-table-column width="120px" prop="advice_group_name" label="医嘱组套名称" />
        <el-table-column width="160px" prop="is_custom" label="是否自定义编辑医嘱" />
      </el-table>
    </BaseDialog>

    <batchLongIerm v-if="isBatchLongIerm" ref="batchLongIermRef" :patientId="patientId" :type="2"
      @refreshDataList="getDataList"></batchLongIerm>
    <addLongIerm v-if="isAddLongIerm" ref="addLongIermRef" :patientId="patientId" :recordId="record_id"
      @refreshDataList="getDataList"></addLongIerm>
  </div>
</template>
<script>
  import { reactive, toRefs, onMounted, nextTick, watch } from 'vue'
  import service from '@/utils/request'
  import { getConstHei, clone } from '@/utils/tool.js'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import batchLongIerm from '@/views/patient/Details/advice/batchLongIerm.vue'
  import addLongIerm from './addLongIerm.vue'
  import BaseDialog from '@/components/Dialog/index.vue'
  import dayjs from 'dayjs'
  export default {
    name: 'programme',
    components: {
      addLongIerm,
      batchLongIerm,
      BaseDialog,
    },
    props: ['patientData', 'searchListData'],
    emits: ['stepsUpdate'],
    setup(props, ctx) {
      const state = reactive({
        ruleForm: {},
        infoHei: '',
        searchData: {
          date: '2022-3-15',
        },
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableData: [],
        tableHei1: 0,
        historyTable: [],
        isHistory: false,
        isBatchLongIerm: false,
        batchLongIermRef: null,
        isAddLongIerm: false,
        addLongIermRef: null,
        tableLoading: false,
        patientId: 0,
        record_id: 0,
        istable1: false,
        tableBoxRef1: null
      })

      watch(
        () => props.patientData.patient_id,
        (val) => {
          console.log(val)
          if (val) {
            state.patientId = val
            state.record_id = props.patientData.record_id
          } else {
            state.patientId = 0
            state.record_id = 0
          }
          state.searchData.date = props.searchListData.time
          getDataList()
        }
      )

      onMounted(() => {
        state.istable1 = false
        state.tableHei1 = localStorage.getItem('tableHeight') || 300
        setTimeout(() => {
          state.istable1 = true
        }, 100);
        state.infoHei = getConstHei()
        if (props.patientData.patient_id) {
          state.patientId = props.patientData.patient_id
          state.record_id = props.patientData.record_id
        } else {
          state.patientId = 0
          state.record_id = 0
        }
        state.searchData.date = props.searchListData.time
        getDataList()
      })
      const getDataList = async () => {
        state.searchData.patient_id = state.patientId
        state.searchData.record_id = state.record_id
        state.tableLoading = true
        let res = await service.post(
          '/api/patient/get_tmp_patient_advice_list',
          state.searchData
        )
        state.tableLoading = false
        if (res.code === 0) {
          res.data.list.forEach(element => {
            if (element.is_report == 1) {
              element.is_report1 = true
            } else {
              element.is_report1 = false
            }
          });
          state.tableData = res.data.list

          state.pageData.totalSum = res.data.total
        }
      }

      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }
      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }

      const modParient = (item) => {
        state.isAddLongIerm = true
        nextTick(() => {
          state.addLongIermRef.initData(item)
        })
      }
      const delParient = (item) => {
        ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post(
              '/api/patient/delete_his_patient_advice',
              {
                id: item.id,
              }
            )
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
              ctx.emit('stepsUpdate')
            }
          })
          .catch(() => { })
      }
      const adviceDoChe = async (val, row) => {
        const item = clone(row)
        if (!val) {
          let res = await service.post(
            '/api/patient/cancel_do_tmp_patient_advice',
            {
              id: item.id,
            }
          )
          if (res.code === 0) {
            ElMessage.success(res.msg)
            ctx.emit('stepsUpdate')
          }
          getDataList()
        } else {
          if (!item.do_time) {
            item.do_time = dayjs(new Date()).format('HH:mm:ss')
          }
          let res = await service.post('/api/patient/do_tmp_patient_advice', {
            id: item.id,
            do_time: item.do_time,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            ctx.emit('stepsUpdate')
          }
          getDataList()
        }
      }
      const adviceCheckChe = async (val, row) => {
        const item = clone(row)
        if (!val) {
          let res = await service.post(
            '/api/patient/cancel_check_tmp_patient_advice',
            {
              id: item.id,
            }
          )
          if (res.code === 0) {
            ElMessage.success(res.msg)
          }
          getDataList()
        } else {
          if (!item.check_time) {
            item.check_time = dayjs(new Date()).format('HH:mm:ss')
          }
          let res = await service.post('/api/patient/check_tmp_patient_advice', {
            id: item.id,
            check_time: item.check_time,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
          }
          getDataList()
        }
      }
      const reportAdvice = async (val, item) => {
        let a = ''
        if (val == true) {
          a = 1
        } else {
          a = 0
        }
        let row = {
          id: item.id,
          is_report: a
        }
        let res = await service.post('/api/patient/edit_tmp_patient_advice', row)
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
        }
      }
      const editAdvice = async (val, item, type) => {
        if (val == '') {
          ElMessage.error('请输入具体数值')
          return
        }
        let row = {
          id: item.id,
        }
        if (type == 1) {
          row.dosage = val
        } else if (type == 2) {
          row.num = val
        } else if (type == 3){
          row.start_time = val
        } else if (type == 4) {
          row.sort = val
        }
        let res = await service.post('/api/patient/edit_tmp_patient_advice', row)
        if (res.code === 0) {
          ElMessage.success(res.msg)
          getDataList()
        }
      }
      const historyParient = async (item) => {
        let res = await service.post('/api/dialysis_record/get_his_list', {
          data_id: item.id,
        })
        if (res.code === 0) {
          state.historyTable = res.data.records
          state.isHistory = true
        }
      }

      const openWeight = (type) => {
        let da = JSON.parse(localStorage.getItem('ms_userData'))
        console.log(da.role.role_type)
        if (da.role.role_type == 3) {
          ElMessage.error('暂无权限')
        } else {
          if (!state.patientId || state.patientId === 0) {
            ElMessage({
              message: '请先选择患者',
              type: 'warning',
            })
            return
          }
          switch (type) {
            case 'batchLongIerm':
              state.isBatchLongIerm = true
              nextTick(() => {
                state.batchLongIermRef.initData()
              })
              break
            case 'addLongIerm':
              state.isAddLongIerm = true
              nextTick(() => {
                state.addLongIermRef.initData()
              })
              break
          }
        }
      }

      return {
        ...toRefs(state),
        openWeight,
        getDataList,
        handleSizeChange,
        handleCurrentChange,
        modParient,
        delParient,
        adviceDoChe,
        adviceCheckChe,
        historyParient,
        editAdvice,
        reportAdvice
      }
    },
  }
</script>
<style scoped lang="scss">
  .el-table {
    &:deep(.el-date-editor.el-input) {
      width: 98%;
    }
  }
  .patentInfo {
    margin-top: 8px;
    height: 100%;
  }
  .cont{
    height: 100%;
  }
  .updateBox {
    height: 37px;
    margin-bottom: 24px;
    margin-top: 20px;

    .el-radio-group {
      margin-right: 30px;
    }
  }

  .type {
    font-size: 18px;
    color: #000000;
    line-height: 32px;
  }

  .parient-tag {
    .el-tag {
      margin-right: 10px;
    }
  }

  .checkBox {
    position: relative;
    z-index: 2;
  }

  .tables {
    height: calc(100% - 46px);
  }
</style>